<template>
	<div>
		<!-- 公共头部 -->
		<!-- <Header :title="title"></Header> -->
		
		<div class="section1">
			<router-link to="/contact" class="item active">联系方式</router-link>
			<router-link to="/message" class="item">留言反馈</router-link>
		</div>
		<div class="section2">
			<ul class="list">
				<li class="item" v-for="(item,index) in list" :key="index">
					<div class="cate-title">{{item.title}}</div>
					<div class="box">
						<div class="pic">
							<baidu-map class="allmap" :center="{lng: item.lng, lat: item.lat}" :zoom="12" :scroll-wheel-zoom="true">
								<bm-marker :position="{lng: item.lng, lat: item.lat}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
							</baidu-map>
						</div>
						<div class="content">
							<div class="info">
								<img class="icon" src="@/assets/images/icon10.png">{{item.name}}
							</div>
							<div class="info">
								<img class="icon" src="@/assets/images/icon11.png">{{item.tel}}
							</div>
							<div class="info">
								<img class="icon" src="@/assets/images/email.png">{{item.chuanzhen}}
							</div>
							<div class="info">
								<img class="icon" src="@/assets/images/icon13.png">{{item.address}}
							</div>
							<!-- <div class="info">
								<img class="icon" src="@/assets/images/icon14.png">{{item.bus}}
							</div> -->
						</div>
					</div>
				</li>
			</ul>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				title: '联系我们',
				list: []
			}
		},
		created() {
			this.getList();
		},
		methods: {
			async getList() {
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/school/school');
				console.log(res);
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学校简介 联系方式：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var list = res.data;
					for(var i = 0; i < list.length; i++){
						if(list[i]['jingwei']){
							var jingweiArr = list[i]['jingwei'].split(',');
							list[i]['lng'] = jingweiArr[0];
							list[i]['lat'] = jingweiArr[1];
						} else {
							list[i]['lng'] = 116.404;
							list[i]['lat'] = 39.915;
						}
					}
					this.list = list;
				} else {
					this.list = [];
				}
			}
		},
	};
</script>

<style scoped>
	.section1{
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: #F3F3F3;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.section1 .item{
		flex: 1;
		font-size: 0.28rem;
		color: #969897;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 0.8rem;
	}
	.section1 .item.active{
		font-size: 0.32rem;
		color: #FFFFFF;
		background: #86cb08;
		position: relative;
	}
/* 	.section1 .item.active::after{
		content: '';
		display: inline-block;
		position: absolute;
		width: 0.48rem;
		height: 0.04rem;
		background: #ff6000;
		border-radius: 0.02rem;
		bottom: 0.08rem;
		left: 50%;
		transform: translate(-50%,0);
		-ms-transform: translate(-50%,0);
		-o-transform: translate(-50%,0);
		-moz-transform: translate(-50%,0);
		-webkit-transform: translate(-50%,0);
	} */
	
	.section2{
		
	}
	.section2 .list{
		padding: 0.32rem 0.3rem 0.4rem;
	}
	.section2 .list .item{
		margin-top: 0.3rem;
	}
	.section2 .list .item:first-child{
		margin-top: 0;
	}
	.section2 .list .item .cate-title{
		height: 0.9rem;
		display: flex;
		align-items: center;
		font-size: 0.32rem;
		color: #333333;
		font-weight: bold;
		position: relative;
	}
	.section2 .list .item .box{
		background: #ffffff;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
		padding: 0.2rem;
	}
	.section2 .list .item .pic{
		width: 100%;
		height: 4.3rem;
	}
	.section2 .list .item .pic .allmap{
		width: 100%;
		height: 100%;
	}
	.section2 .list .item .content{
		
	}
	.section2 .list .item .content .info{
		font-size: 0.26rem;
		color: #333333;
		line-height: 0.32rem;
		margin-top: 0.2rem;
		line-height: 0.4rem;
		padding-left: 0.36rem;
		position: relative;
	}
	.section2 .list .item .content .info .icon{
		height: 0.24rem;
		position: absolute;
		left: 0;
		top: 0.08rem;
	}
</style>